import Splide from "@splidejs/splide";

document.addEventListener('DOMContentLoaded', function () {
  new Splide('.splide', {
    autoplay: true,
    rewind: true,
    focus: 'center',
    fixedWidth: "24rem",
    interval: 3000,
    rewind: true,
    arrows: false,
    gap: 10,
    padding: 10
  }).mount();

  //cloud animation
  document.querySelector("#cloud-lt").classList.remove('-left-96');
  document.querySelector("#cloud-lt").classList.add('-left-16');

  document.querySelector("#cloud-lb").classList.remove('-left-96');
  document.querySelector("#cloud-lb").classList.add('-left-24');

  document.querySelector("#cloud-rt").classList.remove('-right-96');
  document.querySelector("#cloud-rt").classList.add('-right-4');

  document.querySelector("#cloud-rb").classList.remove('-right-96');
  document.querySelector("#cloud-rb").classList.add('-right-20');


  let cadpaCon = document.querySelector("#cadpa-popup");
  document.querySelector("#cadpa-btn").addEventListener('click', function() {
    cadpaCon.classList.remove("hidden");
  });
  document.querySelector("#cadpa-btn-close").addEventListener('click', function(){
    cadpaCon.classList.add("hidden");
  })
});
